<template>
  <div class="mt-2">
    <v-row no-gutters v-if="fieldsLoading">
      <v-col v-for="n in 9" :key="n" cols="12" sm="6" lg="3">
       <v-sheet class="mt-2">
        <v-skeleton-loader class="mx-auto" type="list-item"></v-skeleton-loader>
      </v-sheet>
      </v-col>
    </v-row>
    <form-data v-else :references.sync="formReferences" :model="modelObj" ref="pressuretestReference">
      <template v-if="modelObj.id && listOfDocuments.length > 0" slot="pressuretest_documents">
        <documents :documents="listOfDocuments" :deleteIds="deleteDocumentIds"></documents>
      </template>
    </form-data>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import { hostAppApi } from '@/plugins/axios_settings'
import PermissionsMixin from '@/mixins/permissions'

export default {
  mixins: [PermissionsMixin],
  data () {
    return {
      listOfProjects: [],
      projectObj: {},
      modelObj: {
        id: parseInt(this.$route.params.id),
        po_ranked: 0,
        project_id: parseInt(this.$route.params.project_id)
      },
      loading: false,
      hostRef: this.$cookie.get('WelditPwa_host-ref'),
      projectId: parseInt(this.$route.params.project_id) || 0,
      fieldsLoading: false,
      hostUserId: this.$cookie.get(process.env.VUE_APP_HOST_USER) ? this.$cookie.get(process.env.VUE_APP_HOST_USER) : 0,
      backUrl: '',
      listOfDocuments: [],
      deleteDocumentIds: [],
      hideDetails: true
    }
  },
  computed: {
    ...mapGetters(['formType', 'generalSettings', 'userDetails']),
    formReferences () {
      return {
        cardFlat: true,
        cardTitleClass: 'pa-1',
        layoutClass: 'pa-1',
        title: this.modelObj.id ? this.$t('message.common.update') : `${this.$t('message.common.addNew')}`,
        properties: [{
          model: 'report_nr',
          type: this.formType.TEXT,
          rules: [],
          label: this.$t('message.projectFeature.reportNumber'),
          class: 'xs12 sm4 md3 lg3'
        }, {
          model: 'po_ranked',
          type: this.formType.TEXT,
          rules: this.$_requiredValidation,
          label: this.$t('message.projectFeature.poRanked'),
          class: 'xs12 sm4 md3 lg3'
        }, {
          model: 'description',
          type: this.formType.TEXTAREA,
          rules: [],
          label: this.$t('message.deviation.description'),
          class: 'xs12 sm4 md3 lg3',
          rows: 3
        }, {
          model: 'is_completed',
          rules: [],
          type: this.formType.CHECKBOX,
          class: 'xs12 sm6 md4 lg3 pt-2',
          label: this.$t('message.customModule.isCompleted')
        }, {
          model: 'files',
          type: this.formType.FILES,
          multiple: true,
          rules: this.$_maxFileSizeValidation(100, this.modelObj.files), // [], // this.$_multiSelectValidation
          label: this.$t('message.customModule.uploadDocument'),
          class: 'xs12 sm4 md3',
          slot: 'pressuretest_documents',
          hideDetails: this.hideDetails
        }],
        buttons: [{
          name: 'save_pressuretest',
          color: 'success',
          label: this.modelObj.id ? this.$t('message.common.update') : this.$t('message.common.save'),
          click: this.saveUpdateHandler,
          loading: this.loading,
          is_show: true
        }, {
          name: 'back',
          color: 'info',
          click: () => this.$router.go(-1), //  this.$router.push(this.backUrl),
          label: this.$t('message.login.back'),
          to: this.backUrl,
          is_show: true
        }]
      }
    }
  },
  mounted () {
    if (!this.hostRef.includes(this.$store.state.common.host)) {
      this.hostRef += this.$store.state.common.host
    }
    /* new crud codes adding */
    if (this.$route.params.id) {
      this.getSingleRecord(this.$route.params.id)
    }
    // set back url
    this.backUrl = this.projectId ? `/projects/${this.projectId}/pressure-test-report` : '/pressure-test-report'
    if (this.projectId) this.getProjectById()
  },
  components: {
    'form-data': () => import('@/components/FormTemplate'),
    documents: () => import('@/components/Documents/Documents')
  },
  methods: {
    getProjectById () {
      this.$api.execute('get', `projects/${this.projectId}`).then((response) => {
        if (response.data) {
          this.projectObj = this.$formatter.cloneVariable(response.data)
          this.modelObj.po_ranked = this.projectObj.number
        }
      })
    },
    getAllProjects () {
      this.$api.execute('get', 'projects/get_as_simplified_for_pwa?filter=2').then((response) => {
        this.listOfProjects = response.data
      })
    },
    saveUpdateHandler () {
      if (this.$refs.pressuretestReference.$refs.validateForm.validate()) {
        this.loading = true
        const files = this.modelObj.files
        const model = this.$formatter.cloneVariable(this.modelObj)
        model.user_id = parseInt(this.hostUserId)
        const formData = new FormData()
        if (files) {
          const Newfile = [...files]
          Newfile.forEach((file, i) => {
            formData.append('document[' + i + ']', file)
          })
        }
        const newobj = this.$formatter.removeInvalidFromObject(model)
        setTimeout(() => {
          for (const key in newobj) {
            if (key !== 'files') formData.append(key, key === 'is_completed' ? newobj[key] : newobj[key] || null)
          }
          const header = {
            'Content-Type': 'multipart/form-data'
          }
          let url = `https://${this.hostRef}/api/`
          // const method = model.id ? 'put' : 'post'
          url += model.id ? `project_pressure_test/${model.id}/update` : 'project_pressure_test'
          const text = model.id ? 'message.common.updatedSuccess' : 'message.common.addedSucess'
          /* on update check if there is documents in array to delete */
          const ids = [...this.deleteDocumentIds]
          if (model.id && ids.length > 0) formData.append('document_delete_ids', ids)
          hostAppApi.post(url, formData, { headers: header }) // `${url}?locale=${this.$i18n.locale}`
            .then((response) => {
              this.$root.$emit('snackbar', { snackbar: true, color: 'success', text })
              this.$router.push(this.backUrl)
            })
            // .catch(error => {
            //   if (error.response) {
            //     let exception = error.response
            //     if (error.response.data) exception = `Status Code : ${error.response.status}, Message : ${error.response.data}`
            //     else exception = `Status Code : ${error.response.status}, Message : ${error.response.statusText}`
            //     this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: JSON.stringify(exception), isException: true })
            //     if (error.response.status === 401) {
            //       this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.authFailed' })
            //       this.$store.dispatch('logout')
            //       this.$router.push('/login').catch(() => {})
            //     }
            //   } else {
            //     this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: JSON.stringify(error.message), isException: true })
            //   }
            // })
            .finally(() => {
              this.loading = false
            })
        }, 50)
      } else this.$root.$emit('snackbar', { snackbar: true, color: 'error', text: 'message.common.validationIssue' })
    },
    setProjectname (project) {
      let projectName = ''
      projectName += project.number ? `${project.number} - ` : ''
      projectName += project.po_name ? `${project.po_name} - ` : ''
      projectName += project.name ? project.name : ''
      return projectName
    },
    getSingleRecord (id) {
      this.fieldsLoading = true
      hostAppApi.get(`https://${this.hostRef}/api/project_pressure_test/${id}/edit?user_id=${this.hostUserId}&project_id=${this.projectId}`)
        .then((response) => {
          if (response.data) {
            this.modelObj = this.$formatter.cloneVariable(response.data)
            this.modelObj.project_id = this.projectId
            this.listOfDocuments = this.$formatter.removeInvalidFromArrayObject(this.modelObj.documents)
            this.modelObj.po_ranked = this.projectObj.number
          }
        })
        .finally(() => {
          this.fieldsLoading = false
        })
    }
  },
  watch: {
    isShowDocumentErrors (val) {
      this.hideDetails = !val
    }
  }
}
</script>

<style>

</style>
